import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

export const AuthCheck = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const userId = JSON.parse(localStorage.getItem('userId')) || null
    const requestedLocation = localStorage.getItem('path') || '/'

    useEffect(() => {
        if (userId === null) {
            navigate('/login')
            setLoading(false)
            return
        }

        fetch(`https://api.refactory.app/api/v1/users/${userId}`)
            .then((res) => res.json())
            .then((data) => {
                if (data?.user?.role === 'admin') {
                    navigate(requestedLocation)
                    localStorage.removeItem('path')
                } else {
                    toast.error(
                        'You are not allowed to login!. Your ip, browser information and device details has been recorded for future investigation.',
                        {
                            icon: '🚨',
                            duration: 10000
                        }
                    )
                    navigate('/login')
                }
            })
            .catch((error) => {
                console.error('Error fetching user data:', error)
                navigate('/login')
            })
            .finally(() => {
                setLoading(false)
            })
    }, [navigate, userId])

    if (loading) {
        return <p>Loading...</p>
    }

    return <div></div>
}
