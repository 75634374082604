import React from 'react'

const LoadingSpinner = () => {
    return (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-50 animate-fade-in">
            <div className="bg-white p-6 rounded-md shadow-md flex flex-col items-center">
                <div className="border-4 border-gray-200 border-t-4 border-blue-500 w-12 h-12 animate-spin rounded-full"></div>
                <div className="mt-4 text-sm text-gray-600">Loading...</div>
            </div>
        </div>
    )
}

export default LoadingSpinner
