import React, { useEffect, useState } from 'react'
import ProductTable from '../components/products/ProductTable'
import LoadingSpinner from '../util/LoadingSpinner'

export default function Products() {
    const [loading, setLoading] = useState(true)
    const [products, setProducts] = useState([])
    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/products`)
            .then((res) => res.json())
            .then((data) => {
                setProducts(data.products)
                setLoading(false)
            })
    }, [])
    if (loading) {
        return <LoadingSpinner />
    }
    console.log('Page loaded')
    return (
        <>
            <ProductTable products={products} />
        </>
    )
}
