import React, { useState } from 'react'
import { toast } from 'react-hot-toast'
import { FaPlusCircle } from 'react-icons/fa'

const AddProduct = () => {
    const [productData, setProductData] = useState({
        title: '',
        slug: '',
        moreLove: false,
        id: '',
        price: '',
        desc: '',
        image: '',
        category: '',
        totalSell: '',
        condition: [
            { condition: '', price: '' } // Initialize with an empty object
        ],
        vendor: '',
        color: '',
        brand: '',
        featured: false,
        trending: false,
        storage: '',
        weight: '',
        tags: [],
        sizes: [],
        company: '',
        freeShipping: false,
        inventory: '',
        averageRating: 0,
        numOfReviews: 0,
        user: JSON.parse(localStorage.getItem('userId')) // Assuming you'll populate this with the logged-in user's ID
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        const match = name.match(/^(\w+)\[(\d+)\]\.(\w+)$/)

        if (match) {
            const [, fieldName, fieldIndex, subFieldName] = match
            // Ensure productData is properly initialized with the expected structure
            const updatedConditions = [...productData.condition]
            updatedConditions[fieldIndex] = {
                ...updatedConditions[fieldIndex],
                [subFieldName]: value
            }
            setProductData((prevData) => ({
                ...prevData,
                condition: updatedConditions
            }))
        } else {
            setProductData((prevData) => ({
                ...prevData,
                [name]: value
            }))
        }
    }

    const addConditionField = () => {
        setProductData((prevData) => ({
            ...prevData,
            condition: [...prevData.condition, { condition: '', price: '' }]
        }))
    }

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target
        setProductData((prevData) => ({
            ...prevData,
            [name]: checked
        }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        try {
            const response = await fetch('https://api.refactory.app/api/v1/products', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(productData)
            })

            if (response.ok) {
                // Product added successfully, you can redirect or show a success message
                console.log('Product added successfully')
                toast.success('Product has been added!')
            } else {
                // Handle error, maybe show an error message to the user
                toast.error('Failed to add product')
            }
        } catch (error) {
            console.error('Error:', error)
        }
    }

    return (
        <div className="container mx-auto mt-10">
            <h1 className="text-2xl font-semibold mb-6">Add New Product</h1>
            <form onSubmit={handleSubmit}>
                {/* Product Title */}
                <div className="mb-4">
                    <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                        Product Title
                    </label>
                    <input
                        type="text"
                        id="title"
                        name="title"
                        value={productData.title}
                        onChange={handleChange}
                        className="mt-1 p-2 border rounded w-full"
                        required
                    />
                </div>

                {/* Slug */}
                <div className="mb-4">
                    <label htmlFor="slug" className="block text-sm font-medium text-gray-700">
                        Slug
                    </label>
                    <input
                        type="text"
                        id="slug"
                        name="slug"
                        value={productData.slug}
                        onChange={handleChange}
                        className="mt-1 p-2 border rounded w-full"
                        required
                    />
                </div>

                {/* More Love */}
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">
                        Top Product
                        <input
                            type="checkbox"
                            name="moreLove"
                            checked={productData.moreLove}
                            onChange={handleCheckboxChange}
                            className="ml-2"
                        />
                    </label>
                </div>

                {/* ID */}
                <div className="mb-4">
                    <label htmlFor="id" className="block text-sm font-medium text-gray-700">
                        ID
                    </label>
                    <input
                        type="text"
                        id="id"
                        name="id"
                        value={productData.id}
                        onChange={handleChange}
                        className="mt-1 p-2 border rounded w-full"
                        required
                    />
                </div>

                {/* Price */}
                <div className="mb-4">
                    <label htmlFor="price" className="block text-sm font-medium text-gray-700">
                        Price
                    </label>
                    <input
                        type="number"
                        id="price"
                        name="price"
                        value={productData.price}
                        onChange={handleChange}
                        className="mt-1 p-2 border rounded w-full"
                        required
                    />
                </div>

                {/* Description */}
                <div className="mb-4">
                    <label htmlFor="desc" className="block text-sm font-medium text-gray-700">
                        Description
                    </label>
                    <textarea
                        id="desc"
                        name="desc"
                        value={productData.desc}
                        onChange={handleChange}
                        rows="4"
                        className="mt-1 p-2 border rounded w-full"
                        required
                    />
                </div>

                {/* Image */}
                <div className="mb-4">
                    <label htmlFor="image" className="block text-sm font-medium text-gray-700">
                        Image URL
                    </label>
                    <input
                        type="text"
                        id="image"
                        name="image"
                        value={productData.image}
                        onChange={handleChange}
                        className="mt-1 p-2 border rounded w-full"
                        required
                    />
                </div>

                {/* Category */}
                <div className="mb-4">
                    <label htmlFor="category" className="block text-sm font-medium text-gray-700">
                        Category
                    </label>
                    <select
                        id="category"
                        name="category"
                        value={productData.category}
                        onChange={handleChange}
                        className="mt-1 p-2 border rounded w-full"
                        required
                    >
                        <option value="">Select Category</option>
                        <option value="iPhone">iPhone</option>
                        <option value="Macbook">Macbook</option>
                        <option value="Laptop">Laptop</option>
                        <option value="Samsung">Samsung</option>
                    </select>
                </div>

                {/* Total Sell */}
                <div className="mb-4">
                    <label htmlFor="totalSell" className="block text-sm font-medium text-gray-700">
                        Total Sell
                    </label>
                    <input
                        type="number"
                        id="totalSell"
                        name="totalSell"
                        value={productData.totalSell}
                        onChange={handleChange}
                        className="mt-1 p-2 border rounded w-full"
                    />
                </div>

                {/* Condition */}
                <div className="mb-4">
                    <label htmlFor="condition" className="block text-sm font-medium text-gray-700">
                        Condition and Price
                    </label>
                    {productData.condition.map((item, index) => (
                        <div key={index}>
                            <input
                                type="text"
                                name={`condition[${index}].condition`}
                                placeholder="Condition"
                                value={item.condition}
                                onChange={handleChange}
                                className="mt-1 p-2 border rounded w-full"
                                required
                            />
                            <input
                                type="number"
                                name={`condition[${index}].price`}
                                placeholder="Price"
                                value={item.price}
                                onChange={handleChange}
                                className="mt-1 p-2 border rounded w-full"
                                required
                            />
                        </div>
                    ))}
                    <button type="button" onClick={addConditionField} className="text-blue-600 hover:underline">
                        Add Condition
                    </button>
                </div>

                {/* Vendor */}
                <div className="mb-4">
                    <label htmlFor="vendor" className="block text-sm font-medium text-gray-700">
                        Vendor
                    </label>
                    <input
                        type="text"
                        id="vendor"
                        name="vendor"
                        value={productData.vendor}
                        onChange={handleChange}
                        className="mt-1 p-2 border rounded w-full"
                    />
                </div>

                {/* Color */}
                <div className="mb-4">
                    <label htmlFor="color" className="block text-sm font-medium text-gray-700">
                        Color (Comma Separated)
                    </label>
                    <input
                        type="text"
                        id="color"
                        name="color"
                        value={productData.color}
                        onChange={handleChange}
                        className="mt-1 p-2 border rounded w-full"
                    />
                </div>

                {/* Brand */}
                <div className="mb-4">
                    <label htmlFor="brand" className="block text-sm font-medium text-gray-700">
                        Brand
                    </label>
                    <input
                        type="text"
                        id="brand"
                        name="brand"
                        value={productData.brand}
                        onChange={handleChange}
                        className="mt-1 p-2 border rounded w-full"
                    />
                </div>

                {/* Featured */}
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">
                        Featured
                        <input
                            type="checkbox"
                            name="featured"
                            checked={productData.featured}
                            onChange={handleCheckboxChange}
                            className="ml-2"
                        />
                    </label>
                </div>

                {/* Trending */}
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">
                        Trending
                        <input
                            type="checkbox"
                            name="trending"
                            checked={productData.trending}
                            onChange={handleCheckboxChange}
                            className="ml-2"
                        />
                    </label>
                </div>

                {/* Variations */}
                <div className="mb-4">
                    <label htmlFor="storage" className="block text-sm font-medium text-gray-700">
                        Storage
                    </label>
                    <input
                        type="text"
                        id="storage"
                        name="storage"
                        value={productData?.storage}
                        onChange={handleChange}
                        className="mt-1 p-2 border rounded w-full"
                    />
                </div>

                {/* Weight */}
                <div className="mb-4">
                    <label htmlFor="weight" className="block text-sm font-medium text-gray-700">
                        Weight
                    </label>
                    <input
                        type="number"
                        id="weight"
                        name="weight"
                        value={productData.weight}
                        onChange={handleChange}
                        className="mt-1 p-2 border rounded w-full"
                    />
                </div>

                {/* Tags */}
                <div className="mb-4">
                    <label htmlFor="tags" className="block text-sm font-medium text-gray-700">
                        Tags (comma-separated)
                    </label>
                    <input
                        type="text"
                        id="tags"
                        name="tags"
                        value={productData.tags}
                        onChange={handleChange}
                        className="mt-1 p-2 border rounded w-full"
                    />
                </div>

                {/* Sizes */}
                <div className="mb-4">
                    <label htmlFor="sizes" className="block text-sm font-medium text-gray-700">
                        Sizes (comma-separated)
                    </label>
                    <input
                        type="text"
                        id="sizes"
                        name="sizes"
                        value={productData.sizes}
                        onChange={handleChange}
                        className="mt-1 p-2 border rounded w-full"
                    />
                </div>

                {/* Company */}
                <div className="mb-4">
                    <label htmlFor="company" className="block text-sm font-medium text-gray-700">
                        Company
                    </label>
                    <select
                        id="company"
                        name="company"
                        value={productData.company}
                        onChange={handleChange}
                        className="mt-1 p-2 border rounded w-full"
                        required
                    >
                        <option value="">Select Company</option>
                        <option value="Nokia">Nokia</option>
                        <option value="Samsung">Samsung</option>
                        <option value="Apple">Apple</option>
                        <option value="Apple">OnePlus</option>
                        <option value="Apple">Google</option>
                    </select>
                </div>

                {/* Free Shipping */}
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">
                        Free Shipping
                        <input
                            type="checkbox"
                            name="freeShipping"
                            checked={productData.freeShipping}
                            onChange={handleCheckboxChange}
                            className="ml-2"
                        />
                    </label>
                </div>

                {/* Inventory */}
                <div className="mb-4">
                    <label htmlFor="inventory" className="block text-sm font-medium text-gray-700">
                        Inventory
                    </label>
                    <input
                        type="number"
                        id="inventory"
                        name="inventory"
                        value={productData.inventory}
                        onChange={handleChange}
                        className="mt-1 p-2 border rounded w-full"
                        required
                    />
                </div>

                {/* Average Rating */}
                <div className="mb-4">
                    <label htmlFor="averageRating" className="block text-sm font-medium text-gray-700">
                        Average Rating
                    </label>
                    <input
                        type="number"
                        id="averageRating"
                        name="averageRating"
                        value={productData.averageRating}
                        onChange={handleChange}
                        className="mt-1 p-2 border rounded w-full"
                    />
                </div>

                {/* Number of Reviews */}
                <div className="mb-4">
                    <label htmlFor="numOfReviews" className="block text-sm font-medium text-gray-700">
                        Number of Reviews
                    </label>
                    <input
                        type="number"
                        id="numOfReviews"
                        name="numOfReviews"
                        value={productData.numOfReviews}
                        onChange={handleChange}
                        className="mt-1 p-2 border rounded w-full"
                    />
                </div>

                {/* User */}
                <div className="mb-4">
                    <label htmlFor="user" className="block text-sm font-medium text-gray-700">
                        User ID
                    </label>
                    <input
                        type="text"
                        id="user"
                        name="user"
                        value={JSON.parse(localStorage.getItem('userId'))}
                        disabled
                        onChange={handleChange}
                        className="mt-1 p-2 border rounded w-full"
                        required
                    />
                </div>

                {/* Submit Button */}
                <div className="mt-4">
                    <button
                        type="submit"
                        className="flex items-center bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                    >
                        <FaPlusCircle className="mr-2" />
                        Add Product
                    </button>
                </div>
            </form>
        </div>
    )
}

export default AddProduct
