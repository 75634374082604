import React, { useState, useEffect } from 'react'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'

const pageSize = 10

const BusinessList = () => {
    const [businesses, setBusinesses] = useState([])
    const [currentPage, setCurrentPage] = useState(1)

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/business`)
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    setBusinesses(data.business)
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error)
            })
    }, [])

    const pageSize = 10
    const lastIndex = currentPage * pageSize
    const firstIndex = lastIndex - pageSize
    const currentBusinesses = businesses.slice(firstIndex, lastIndex)

    const handlePageChange = (type) => {
        if (type === 'prev' && currentPage > 1) {
            setCurrentPage(currentPage - 1)
        } else if (type === 'next' && lastIndex < businesses.length) {
            setCurrentPage(currentPage + 1)
        }
    }

    return (
        <div className="container mx-auto px-4">
            <h1 className="text-3xl font-bold text-center my-4">Business List</h1>
            <table className="table-auto w-full">
                <thead>
                    <tr>
                        <th className="px-4 py-2">Name</th>
                        <th className="px-4 py-2">Email</th>
                        <th className="px-4 py-2">Phone Number</th>
                        <th className="px-4 py-2">Business Name</th>
                        <th className="px-4 py-2">Business Address</th>
                        <th className="px-4 py-2">Country</th>
                        <th className="px-4 py-2">Position</th>
                        <th className="px-4 py-2">Company Website</th>
                        <th className="px-4 py-2">Created At</th>
                    </tr>
                </thead>
                <tbody>
                    {currentBusinesses.map((business) => (
                        <tr key={business._id}>
                            <td className="border px-4 py-2">{business.name}</td>
                            <td className="border px-4 py-2">{business.email}</td>
                            <td className="border px-4 py-2">{business.phoneNumber}</td>
                            <td className="border px-4 py-2">{business.businessName}</td>
                            <td className="border px-4 py-2">{business.businessAddress}</td>
                            <td className="border px-4 py-2">{business.country}</td>
                            <td className="border px-4 py-2">{business.position}</td>
                            <td className="border px-4 py-2">
                                <a href={business.companyWebsite} target="_blank" rel="noopener noreferrer">
                                    {business.companyWebsite}
                                </a>
                            </td>
                            <td className="border px-4 py-2">{new Date(business.createdAt).toLocaleString()}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="flex justify-center mt-4">
                <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    onClick={() => handlePageChange('prev')}
                >
                    <FiChevronLeft />
                </button>
                <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-2"
                    onClick={() => handlePageChange('next')}
                >
                    <FiChevronRight />
                </button>
            </div>
        </div>
    )
}

export default BusinessList
