import React, { useEffect, useState } from 'react'
import LoadingSpinner from '../util/LoadingSpinner'

const OrderList = () => {
    const [orders, setOrders] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        async function fetchOrders() {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/orders`)
                const data = await response.json()
                setOrders(data.orders)
                setLoading(false)
            } catch (error) {
                console.error('Error fetching orders:', error)
                setLoading(false)
            }
        }

        fetchOrders()
    }, [])

    if (loading) {
        return <LoadingSpinner />
    }
    const handleEditOrder = (orderId) => {
        // history.push(`/edit-order/${orderId}`) // Replace with your actual edit order route
    }

    const handleDeleteOrder = (orderId) => {
        // Implement your delete logic here
        // You might want to show a confirmation modal before deleting
    }

    return (
        <div className="bg-white shadow-md rounded-md">
            {loading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <table className="min-w-full">
                        <thead>
                            <tr className="bg-gray-100">
                                <th className="py-2 px-4 text-left">Order ID</th>
                                <th className="py-2 px-4 text-left">Customer</th>
                                <th className="py-2 px-4 text-left">Total Amount</th>
                                <th className="py-2 px-4 text-left">Status</th>
                                <th className="py-2 px-4 text-left">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orders.map((order) => (
                                <tr key={order.id} className="border-t">
                                    <td className="py-2 px-4 uppercase">#{order._id.slice(-5)}</td>
                                    <td className="py-2 px-4">{order.userName}</td>
                                    <td className="py-2 px-4">{order.total}</td>
                                    <td className="py-2 px-4">
                                        <span
                                            className={`inline-block py-1 px-3 uppercase rounded-full ${
                                                order.status === 'delivered'
                                                    ? 'bg-green-700 text-white'
                                                    : order.status === 'paid'
                                                    ? 'bg-green-500 text-white'
                                                    : order.status === 'canceled'
                                                    ? 'bg-red-600 text-white'
                                                    : 'bg-yellow-500 text-gray-800'
                                            }`}
                                        >
                                            {order.status}
                                        </span>
                                    </td>
                                    <td className="py-2 px-4">
                                        <button
                                            className="text-blue-500 hover:underline mr-2"
                                            onClick={() => handleEditOrder(order.id)}
                                        >
                                            Edit
                                        </button>
                                        <button
                                            className="text-red-500 hover:underline"
                                            onClick={() => handleDeleteOrder(order.id)}
                                        >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            )}
        </div>
    )
}

export default OrderList
