import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Layout from './components/shared/Layout'
import Register from './pages/Register'
import Dashboard from './pages/Dashboard'
import Products from './pages/Products'
import Login from './pages/Login'
import { AuthCheck } from './util/AuthCheck'
import AddProductForm from './pages/AddProduct'
import OrderList from './pages/Orders'
import Customers from './pages/Customers'
import EditProduct from './pages/EditProduct'
import SubscriberList from './pages/SubscriberList'
import BusinessList from './pages/Business'

function App() {
    return (
        <Router>
            <Routes>
                <Route
                    path="/"
                    element={
                        <>
                            <AuthCheck />
                            <Layout />
                        </>
                    }
                >
                    <Route index element={<Dashboard />} />
                    <Route path="products" element={<Products />} />
                    <Route path="addproduct" element={<AddProductForm />} />
                    <Route path="orders" element={<OrderList />} />
                    <Route path="customers" element={<Customers />} />
                    <Route path="subs" element={<SubscriberList />} />
                    <Route path="business" element={<BusinessList />} />
                    <Route path="/editproduct/:id" element={<EditProduct />} />
                </Route>
                <Route path="/register" element={<Register />} />
                <Route path="/login" element={<Login />} />
            </Routes>
        </Router>
    )
}

export default App
