import React, { useState, useEffect } from 'react'
import { FaEdit, FaTrash } from 'react-icons/fa'
import ReactPaginate from 'react-paginate'

const Customers = () => {
    const [users, setUsers] = useState([])
    const [currentPage, setCurrentPage] = useState(0)
    const itemsPerPage = 5 // Number of users per page

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users`)
            const data = await response.json()
            setUsers(data.users)
        } catch (error) {
            console.error('Error fetching users:', error)
        }
    }

    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected)
    }

    const renderUsers = () => {
        const startIndex = currentPage * itemsPerPage
        const endIndex = startIndex + itemsPerPage
        const currentUsers = users.slice(startIndex, endIndex)

        return currentUsers.map((user) => (
            <tr key={user._id}>
                <td className="px-4 py-2">{user.name}</td>
                <td className="px-4 py-2">{user.email}</td>
                <td className="px-4 py-2">{user.role}</td>
                <td className="px-4 py-2">
                    <button className="text-blue-500 hover:text-blue-700 mr-2">
                        <FaEdit /> Edit
                    </button>
                    <button className="text-red-500 hover:text-red-700">
                        <FaTrash /> Delete
                    </button>
                </td>
            </tr>
        ))
    }

    return (
        <div className="p-6">
            <table className="w-full border-collapse border rounded-lg">
                <thead className="bg-gray-200">
                    <tr>
                        <th className="border p-4">Name</th>
                        <th className="border p-4">Email</th>
                        <th className="border p-4">Role</th>
                        <th className="border p-4">Actions</th>
                    </tr>
                </thead>
                <tbody>{renderUsers()}</tbody>
            </table>
            <ReactPaginate
                previousLabel={'Prev'}
                nextLabel={'Next'}
                pageCount={Math.ceil(users.length / itemsPerPage)}
                onPageChange={handlePageChange}
                containerClassName={'flex justify-center mt-4 gap-2'}
                previousLinkClassName={'border rounded-l-md px-3 py-2 bg-blue-200'}
                nextLinkClassName={'border rounded-r-md px-3 py-2 bg-blue-200'}
                disabledClassName={'opacity-50'}
                activeClassName={'bg-blue-300'}
            />
        </div>
    )
}

export default Customers
