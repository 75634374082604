import React, { useState } from 'react'
import ReactPaginate from 'react-paginate'
import { AiFillEdit, AiOutlineClose, AiOutlineDelete } from 'react-icons/ai'
import { Link, useNavigate } from 'react-router-dom'

const ProductsTable = ({ products }) => {
    console.log('Product Table')
    const navigate = useNavigate()
    const [currentPage, setCurrentPage] = useState(0)
    const [selectedCategory, setSelectedCategory] = useState('All') // Initial filter
    const itemsPerPage = 10 // You can adjust this as needed

    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected)
    }

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value)
        setCurrentPage(0) // Reset to first page when changing filters
    }

    const filteredProducts =
        selectedCategory === 'All' ? products : products.filter((product) => product.category === selectedCategory)

    const pageCount = Math.ceil(filteredProducts.length / itemsPerPage)
    const offset = currentPage * itemsPerPage
    const currentPageData = filteredProducts.slice(offset, offset + itemsPerPage)
    const handleButtonClick = () => {
        // Implement your button click functionality here
        localStorage.setItem('path', '/addproduct')
        navigate('/addproduct')
    }
    const handleEditProduct = (id) => {
        localStorage.setItem('path', '/editproduct/' + id)
        navigate('/editproduct/' + id)
    }

    return (
        <div className="flex flex-col">
            {/* Filter Dropdown */}
            <div className="mb-4 flex items-center justify-between">
                <div>
                    <label className="mr-2 font-medium">Filter by Category:</label>
                    <select
                        className="px-2 py-1 border rounded"
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                    >
                        <option value="All">All</option>
                        <option value="Phone">Phone</option>
                        {/* Add more category options */}
                    </select>
                </div>
                <button className="ml-4 px-4 py-2 bg-blue-500 text-white rounded-md" onClick={handleButtonClick}>
                    Add Product
                </button>
            </div>

            {/* Table */}
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Product
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Price
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Brand
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Category
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Featured
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Total Sale
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Vendor
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Inventory
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Action
                                    </th>
                                    {/* Add more headers here */}
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {currentPageData.map((product) => (
                                    <tr key={product._id}>
                                        <td className="px-6 py-4 whitespace-nowrap">{product?.title}</td>
                                        <td className="px-6 py-4 whitespace-nowrap">${product.price}</td>
                                        <td className="px-6 py-4 whitespace-nowrap">{product.brand}</td>
                                        <td className="px-6 py-4 whitespace-nowrap">{product.category}</td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            {product.featured ? 'Yes' : 'No'}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">{/* Display total sale */}</td>
                                        <td className="px-6 py-4 whitespace-nowrap">{product.vendor}</td>
                                        <td className="px-6 py-4 whitespace-nowrap">{product.inventory}</td>
                                        <td className="flex flex-row items-center">
                                            <AiFillEdit onClick={() => handleEditProduct(product._id)} />
                                            <AiOutlineClose />
                                            <AiOutlineDelete />
                                        </td>
                                        {/* Add more cells here */}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <ReactPaginate
                previousLabel={'Prev'}
                nextLabel={'Next'}
                pageCount={pageCount}
                onPageChange={handlePageChange}
                containerClassName={'flex justify-center mt-4 gap-2'}
                previousLinkClassName={'border rounded-l-md px-3 py-2 bg-blue-200'}
                nextLinkClassName={'border rounded-r-md px-3 py-2 bg-blue-200'}
                disabledClassName={'opacity-50'}
                activeClassName={'bg-blue-300'}
            />
        </div>
    )
}

export default ProductsTable
