import React, { useState, useEffect } from 'react'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'

const SubscriberList = () => {
    const [subscribers, setSubscribers] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const itemsPerPage = 10

    useEffect(() => {
        fetchSubscribers()
    }, [])

    const fetchSubscribers = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/sub`)
            const data = await response.json()
            setSubscribers(data.sub)
        } catch (error) {
            console.error('Error fetching subscribers: ', error)
        }
    }

    const indexOfLastItem = currentPage * itemsPerPage
    const indexOfFirstItem = indexOfLastItem - itemsPerPage
    const currentItems = subscribers.slice(indexOfFirstItem, indexOfLastItem)

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber)
    }

    const convertToLocalTime = (utcDate) => {
        const localDate = new Date(utcDate)
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true
        }
        return localDate.toLocaleString('en-US', options)
    }

    return (
        <div className="container mx-auto px-4">
            <h1 className="text-3xl font-bold my-4">Subscriber List</h1>
            <table className="min-w-full table-auto">
                <thead>
                    <tr>
                        <th className="px-4 py-2">Email</th>
                        <th className="px-4 py-2">Joined At</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((subscriber) => (
                        <tr key={subscriber._id}>
                            <td className="border px-4 py-2">{subscriber.email}</td>
                            <td className="border px-4 py-2">{convertToLocalTime(subscriber.createdAt)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="flex my-4">
                <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="text-white bg-blue-500 hover:bg-blue-700 font-bold py-2 px-4 rounded"
                >
                    <FiChevronLeft />
                </button>
                <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={indexOfLastItem >= subscribers.length}
                    className="text-white bg-blue-500 hover:bg-blue-700 font-bold py-2 px-4 rounded ml-2"
                >
                    <FiChevronRight />
                </button>
            </div>
        </div>
    )
}

export default SubscriberList
